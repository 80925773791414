import React, {useState} from 'react';
import {Button, DatePicker, Modal} from "antd";
import {useParams} from "react-router-dom";
import instance from "../../../../../../API/api";
import dayjs from "dayjs";
import {sendEvent} from "../../../../../../Utils/analytics";
import {ErrorNotification} from "../../../../../../Utils/data";


const Letter = ({open, setOpen}: { open: boolean, setOpen(open: boolean): void }) => {
    const [loading, setLoading] = useState(false);
    const {id} = useParams<{ id: string }>();
    const {RangePicker} = DatePicker;
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date().setHours(24),
            key: "selection"

        }
    ]);

    const clickHandler = async () => {
        try {
            const {data} = await instance.get(`logs/letter/${id}?start=${state[0]}&end=${state[1]}&access_token=${localStorage.getItem('token')}`);
            window.open(`https://report.ontime-logs.com${data.link}`, '_blank');
            setOpen(false);
        } catch (error: any) {
            ErrorNotification({
                status: error.response && error.response.status,
                message: error.response.data.error.message
            });
        }

    }


    const showModals = () => {
        setOpen(!open)
    };
    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
        clickHandler()
        sendEvent('User/Users/Commands/Letter', 'Submit', 'Calendar Letter', 1)

    };

    const isSubmitDisabled = !state || !state[0] || !state[1];
    return (
        <>
            <Modal
                open={open} title="LETTER" onOk={handleOk} onCancel={showModals}
                footer={[
                    <Button key="back" onClick={showModals}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleOk}
                            disabled={isSubmitDisabled}>
                        Submit
                    </Button>,
                ]}
            >
                <RangePicker onChange={(value: any) => {
                    setState(value.map((item: any) => {
                        return dayjs(item).format('MM-DD-YYYY')
                    }))
                }}/>
            </Modal>
        </>
    );
};

export default Letter;